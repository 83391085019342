import * as types from './Types'

const initialState = {
    CarBrand: [],
    CarModel: [],
    CarFuel: [],
    CarVariant: [],
    CarRTO: [],
    SelectedCarInfo: {},
    StateName: [],
    CityName: [],
    Relation: [],
    Occupation: [],
    Insurer: [],
    PreviousInsurer: [],
    Financers: [],
    quotesData: [],
    fullQuotesData: [],
    ownerData: {},
    PersonalInfo: {},
    VehicleInfo: {},
    AddInfo: {},
    documentUpload: {},
    KycResponse: {},
    vehicleAddInfo: {},
    addRiders: {},
    hdfcStateData: {},
    proposalData: [],
    allProposalData: [],
    getQuotesBasedOn: [],
    AllQuotesData: [],
    respondQuotesData: [],
    cashlessGarage: [],
    getGodigitKyc: [],
    isKycDone: [],
    SelectedInsurerDetails: [],
    loading: true
}

export const CarReducers = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_ALL_MAKES:
            return {
                ...state,
                CarBrand: payload,
                loading: false
            }
        case types.GET_ALL_MODEL:
            return {
                ...state,
                CarModel: payload,
                loading: false
            }
        case types.GET_ALL_FUEL:
            return {
                ...state,
                CarFuel: payload,
                loading: false
            }
        case types.GET_ALL_VARIANT:
            return {
                ...state,
                CarVariant: payload,
                loading: false
            }
        case types.GET_ALL_RTO:
            return {
                ...state,
                CarRTO: payload,
                loading: false
            }

        // ******************************************************************  MakeModal Store
        case types.SELECTED_CAR_DETAILS:
            return {
                ...state,
                SelectedCarInfo: payload,
                loading: false
            }
        case types.SELECTED_INSURARER_DETAILS:
            return {
                ...state,
                SelectedInsurerDetails: payload,
                loading: false
            }

        case types.GET_ALL_STATE:
            return {
                ...state,
                StateName: payload,
                loading: false
            }
        case types.GET_ALL_CITY:
            return {
                ...state,
                CityName: payload,
                loading: false
            }
        case types.GET_ALL_RELATION:
            return {
                ...state,
                Relation: payload,
                loading: false
            }
        case types.GET_ALL_OCCUPATION:
            return {
                ...state,
                Occupation: payload,
                loading: false
            }
        case types.GET_INSURER:
            return {
                ...state,
                Insurer: payload,
                loading: false
            }
        case types.GET_PREVIOUS_INSURER:
            return {
                ...state,
                PreviousInsurer: payload,
                loading: false
            }
        case types.GET_ALL_FINANCER:
            return {
                ...state,
                Financers: payload,
                loading: false
            }


        // ******************************************************************  Quotes
        case types.GET_QUOTES:
            return {
                ...state,
                quotesData: payload,
                loading: false
            }
        case types.GET_ALL_QUOTES:
            return {
                ...state,
                fullQuotesData: payload,
                loading: false
            }
        case types.GET_QUOTES_BASED_LEAD:
            return {
                ...state,
                getQuotesBasedOn: payload,
                loading: false
            }
        case types.RESET_GET_QUOTES:
            return {
                ...state,
                quotesData: initialState.quotesData,
                fullQuotesData: initialState.fullQuotesData
            }

        case types.ADD_ALLQUOTES:
            return {
                ...state,
                AllQuotesData: payload,
                loading: true
            }
        // case types.GET_ALL_ACTIVE_INSURER: return {
        //     ...state,
        //     allActiveInsurer: payload,
        //     loading: true
        // }
        // ******************************************************************  Proposal 
        case types.OWNWE_DETAIL:
            return {
                ...state,
                ownerData: payload,
                loading: false
            }
        case types.PERSONAL_DETAILS:
            return {
                ...state,
                PersonalInfo: payload,
                loading: false
            }
        case types.VEHICLE_INFO:
            return {
                ...state,
                VehicleInfo: payload,
                loading: false
            }
        case types.ADDRESS_DETAIL:
            return {
                ...state,
                AddInfo: payload,
                loading: false
            }
        case types.VEHICLE_ADDRESS_DETAIL:
            return {
                ...state,
                vehicleAddInfo: payload,
                loading: false
            }
        case types.GET_PROPOSAL:
            return {
                ...state,
                proposalData: payload,
                loading: false
            }
        case types.GET_PROPOSAL_PROPOSALID:
            return {
                ...state,
                allProposalData: payload,
                loading: false
            }
        case types.ADD_RIDER:
            return {
                ...state,
                addRiders: payload,
                loading: false
            }

        case types.DOCUMENT_UPLOAD: return {
            ...state,
            documentUpload: payload,
            loading: false
        }
        // ******************************************************************  Cashless Garages
        case types.GET_CASHLESS_GARAGES:
            return {
                ...state,
                cashlessGarage: payload,
                loading: true
            }

        // ******************************************************************  Godigit Kyc
        case types.GET_KYC_DETAIL:
            return {
                ...state,
                getGodigitKyc: payload,
                loading: true
            }
        // ******************************************************************  IsKYC Done Godigit
        case types.GET_GODIGIT_KYC_CONTROLLER:
            return {
                ...state,
                isKycDone: payload,
                loading: true
            }
        case types.KYC_RESPONSE_DATA:
            return {
                ...state,
                KycResponse: payload,
                loading: true
            }
        case types.EMPTY_PERPOSAL_INFO:
            return {
                ...state,
                proposalData: initialState.proposalData,
                isKycDone: initialState.isKycDone,
                KycResponse: initialState.KycResponse,
                hdfcStateData: initialState.hdfcStateData
            }

        case types.STORE_HDFC_STATE_DATA:
            return {
                ...state,
                hdfcStateData: payload,
                loading: false
            }
        // ******************************************************************  Reset Store Data  
        case types.RESET_JOURNEY_FORM_DATA:
            return {
                ...state,
                CarBrand: initialState.CarBrand,
                CarModel: initialState.CarModel,
                CarFuel: initialState.CarFuel,
                CarVariant: initialState.CarVariant,
                CarRTO: initialState.CarRTO,
                AllQuotesData: null,
                fullQuotesData: initialState.fullQuotesData,
                quotesData: initialState.quotesData,
                isKycDone: null,
                SelectedCarInfo: null,
                hdfcStateData: initialState
            }
        default:
            return state
    }
}

export default CarReducers